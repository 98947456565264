import { Action, action } from "easy-peasy";
import { i18n } from "i18next";

export interface I18nModel {
  // * State ----------------
  languageList: ["en", "vi"];

  // * Actions --------------
  changeLanguage: Action<I18nModel, { i18n: i18n; newLanguage: string }>;
}

export const i18nStore: I18nModel = {
  languageList: ["en", "vi"],

  //Actions
  changeLanguage: action((_, payload) => {
    payload.i18n.changeLanguage(payload.newLanguage);
  }),
};

export default i18nStore;
