import { createStore, createTypedHooks } from "easy-peasy";

import {
  I18nModel,
  i18n,
  ThemeModel,
  theme,
  SidebarModel,
  sidebar,
} from "./misc";
import { AnimeAPIModel, animeAPI } from "./AnimeAPI";
import { ResumeModel, resume } from "./Resume";
import { RadioModel, radio } from "./Radio";

import { CrosswordModel, crossword } from "./Crossword";

interface StoreModel {
  i18n: I18nModel;
  theme: ThemeModel;
  sidebar: SidebarModel;

  resume: ResumeModel;

  animeAPI: AnimeAPIModel;
  radio: RadioModel;
  crossword: CrosswordModel;
}

const model: StoreModel = {
  i18n,
  theme,
  sidebar,

  resume,

  animeAPI,
  radio,
  crossword,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();
const store = createStore(model);

export { useStoreState, useStoreActions };
export default store;
