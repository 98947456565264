import * as loading from "assets/animationFile/51-preloader.json";
import Lottie from "react-lottie";
import "./LoadingScreen.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  // @ts-ignore
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoadingScreen = () => {
  return (
    <div className="loading-screen-container">
      <h1>Loading</h1>
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>
  );
};

export default LoadingScreen;
