import React from "react";
import { useSpring, a } from "react-spring";

import "./FloatingOrb.sass";

interface FloatingOrbProps {
  children: React.ReactNode;
  indexInList?: number;
  listLength?: number;
}

export const FloatingOrb = ({
  children,
  indexInList = 1,
  listLength = 1,
}: FloatingOrbProps) => {
  const interp = (radian: number) =>
    `translateY(${
      15 * Math.sin(radian + (indexInList * 2 * Math.PI) / listLength)
    }px)`;

  const { radians } = useSpring({
    from: { radians: 0 },
    to: { radians: 2 * Math.PI },
    loop: true,
    config: { duration: 3500 },
  });

  return <a.div style={{ transform: radians.to(interp) }}>{children}</a.div>;
};
