import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IconInfo {
  icon: [string, string] | readonly [string, string];
  className?: string;
  style?: object;
}
//  // Dynamically import icon
//  import(
//   `@fortawesome/${iconTypeDispatcher[icon[0]]}/${iconFileName(icon[1])}.js`
// ).then((icon) => {
//   library.add(icon.definition);
//   setReady(true);
// });

const Icon = ({ icon, className, style }: IconInfo) => {
  return (
    <FontAwesomeIcon
      icon={icon as IconProp}
      className={className}
      style={style}
    />
  );
};

export default Icon;
