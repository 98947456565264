import { Sidebar } from "components/commons";
import LoadingScreen from "components/commons/LoadingScreen";
import { redirectInfo, routeInfo } from "data";
import { Howler } from "howler";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { a, useTransition } from "react-spring";
import "react-tooltip/dist/react-tooltip.css";
import { useStoreActions } from "store";
import "./App.scss";

const canRedirect = (path: string): path is keyof typeof redirectInfo =>
  redirectInfo.hasOwnProperty(path);

const RouteManager = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Animation
  const transitionAnim = useTransition(location.pathname, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  // Listeners
  useEffect(() => {
    // Stop all sounds on path change
    Howler.stop();

    // Redirection -  Check whether we are in a forbidden land
    if (canRedirect(location.pathname)) {
      const newLocation = redirectInfo[location.pathname];
      navigate(newLocation);
    }
  }, [location.pathname, navigate]);

  return (
    <div className="App-content">
      {transitionAnim((style, path) => {
        const route = routeInfo.find((route) => route.path === path);
        return (
          route && (
            <a.div className="page-transition-wrapper" style={style as any}>
              {route.component}
            </a.div>
          )
        );
      })}
    </div>
  );
};

// const RouteManager = () => {
//   const location = useLocation();

//   // Listeners
//   useEffect(() => {
//     // Stop all sounds on path change
//     Howler.stop();
//   }, []);

//   return (
//     <TransitionGroup className="App-content">
//       <CSSTransition key={location.key} classNames="fade" timeout={1000}>
//         <Switch>
//           {routeInfo.map((route) => (
//             <Route path={route.path} key={`route-${route.path}`}>
//               <div className="page-transition-wrapper">{route.component}</div>
//             </Route>
//           ))}

//           {Object.entries(redirectInfo).map(([path, to], index) => (
//             <Route exact path={path} key={`redirect-${index}`}>
//               <Redirect to={to} />
//             </Route>
//           ))}
//         </Switch>
//       </CSSTransition>
//     </TransitionGroup>
//   );
// };

const App = () => {
  const { initializeTheme } = useStoreActions((actions) => actions.theme);

  const { i18n } = useTranslation();
  const { changeLanguage } = useStoreActions((actions) => actions.i18n);

  useEffect(() => {
    // Make sure Howler create a context
    Howler.mute(false);
    Howler.volume(1);
  }, []);

  useEffect(() => {
    // Set up the initial theme and language
    //  currently default to dark theme and English
    initializeTheme();
    changeLanguage({ i18n, newLanguage: "en" });
  }, [initializeTheme, changeLanguage, i18n]);

  return (
    <Router>
      <div className="App">
        <Sidebar />

        <Suspense fallback={<LoadingScreen />}>
          <RouteManager />
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
