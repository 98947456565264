import { Action, action } from "easy-peasy";

export interface SidebarModel {
  // * State -------------
  isOpen: boolean;

  // * Actions -----------
  toggleIsOpen: Action<SidebarModel>;
}

export const sidebar: SidebarModel = {
  isOpen: true,

  //Actions
  toggleIsOpen: action((state) => {
    state.isOpen = !state.isOpen;
  }),
};

export default sidebar;
