import {
  awards,
  AwardType,
  education,
  EducationType,
  helpList,
  workData,
  WorkDataType,
} from "data";
import { ResumeI18NKey, ResumeI18NMap } from "data/i18n";
import dayjs, { Dayjs } from "dayjs";

export type TerminalCommandStatus = "error" | "warning" | "success";

const terminalCommandTypeList = [
  "help",
  "hello",
  "show",
  "clrscr",
  "unknown",
] as const;
export type TerminalCommandType = typeof terminalCommandTypeList[number];
export const isValidCommand = (
  command: string,
): command is TerminalCommandType =>
  terminalCommandTypeList.includes(command as TerminalCommandType);

// * --- Show command ---
const resumeTypeList = ["work", "education", "awards"] as const;
type ResumeType = typeof resumeTypeList[number];
export const isResumeType = (command: string): command is ResumeType =>
  resumeTypeList.includes(command as ResumeType);

type ShowCommandArgType = [ResumeType, ...string[]];
export const isShowCommandArgType = (
  argList: string[],
): argList is ShowCommandArgType =>
  argList.length >= 1 && isResumeType(argList[0]);

interface BaseTerminalShowResult {
  type: ResumeType;
}

interface TerminalShowWorkResult extends BaseTerminalShowResult {
  type: "work";
  data: WorkDataType[];
}

interface TerminalShowEducationResult extends BaseTerminalShowResult {
  type: "education";
  data: EducationType[];
}

interface TerminalShowAwardsResult extends BaseTerminalShowResult {
  type: "awards";
  data: AwardType[];
}

export interface TerminalShowResult {
  status: TerminalCommandStatus;
  data:
    | TerminalShowWorkResult
    | TerminalShowEducationResult
    | TerminalShowAwardsResult
    | "argError";
}

const showResume = (args: ShowCommandArgType): TerminalShowResult => {
  const showType = args[0];
  // Arg list is an array, but we only take the first one
  return isResumeType(showType)
    ? {
        status: "success",
        data:
          showType === "work"
            ? { type: showType, data: workData.reverse() }
            : showType === "awards"
            ? { type: showType, data: awards }
            : { type: showType, data: education },
      }
    : { status: "warning", data: "argError" };
};

// * --- Help command ---
type HelpI18NKey = keyof ResumeI18NMap["help"];
type HelpDetailI18NKey = keyof ResumeI18NMap["help"]["detail"];
export interface TerminalHelpResult {
  type: HelpI18NKey;
  data: [string, HelpDetailI18NKey, boolean][];
}

const showHelp = (): TerminalHelpResult => ({
  type: "commandList",
  data: helpList,
});

// * --- Hello command ---
export interface TerminalSayHelloResult {
  data: string;
}
const sayHello = (name: string[]): TerminalSayHelloResult => ({
  data: name.join(" "),
});

// * --- Command list ---
export interface CommandListType {
  show: (showType: ShowCommandArgType) => TerminalShowResult;
  help: () => TerminalHelpResult;
  hello: (name: string[]) => TerminalSayHelloResult;
}

export const commandListDispatcher: CommandListType = {
  // Command list dispatcher
  show: showResume,
  help: showHelp,
  hello: sayHello,
};

interface BaseTerminalOutputObject {
  dayjsTime: Dayjs;
  commandType: TerminalCommandType;
  command: string;
  status: TerminalCommandStatus;
  argListString?: string;
}

interface TerminalHelpOutputObject extends BaseTerminalOutputObject {
  commandType: "help";
  resultData: TerminalHelpResult;
}

interface TerminalHelloOutputObject extends BaseTerminalOutputObject {
  commandType: "hello";
  resultData: TerminalSayHelloResult;
}

interface TerminalShowOutputObject extends BaseTerminalOutputObject {
  commandType: "show";
  resultData: TerminalShowResult["data"];
}

interface TerminalClearScreenOutputObject extends BaseTerminalOutputObject {
  commandType: "clrscr";
}

interface TerminalUnknownOutputObject extends BaseTerminalOutputObject {
  commandType: "unknown";
  errorCode?: ResumeI18NKey;
}

export type TerminalOutputObject =
  | TerminalHelpOutputObject
  | TerminalHelloOutputObject
  | TerminalShowOutputObject
  | TerminalClearScreenOutputObject
  | TerminalUnknownOutputObject;

export const initialObject: TerminalOutputObject = {
  dayjsTime: dayjs(),
  commandType: "help",
  command: "help",

  status: "success",
  resultData: {
    type: "help",
    data: [
      ["iButton", "toListCommand", true],
      ["inputField", "toInteract", true],
      ["typeHelp", "toShowCommandList", true],
    ],
  },
};
