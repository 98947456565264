// * --- Kitsu API --- * //
interface Titles {
  en: string;
  en_jp: string;
  ja_jp: string;
}

interface RatingFrequencies {
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
  "9": string;
  "10": string;
  "11": string;
  "12": string;
  "13": string;
  "14": string;
  "15": string;
  "16": string;
  "17": string;
  "18": string;
  "19": string;
  "20": string;
}

interface Size {
  width: number;
  height: number;
}

interface ImageData {
  tiny?: string;
  large?: string;
  small?: string;
  medium?: string;
  original: string;
  meta?: {
    dimensions: { tiny?: Size; large?: Size; small?: Size; medium?: Size };
  };
}

interface Relationship {
  links: { self: string; related: string };
}

export interface KitsuAnimeData {
  id: string;
  type: "anime";

  links: { self: string };

  attributes: {
    createdAt?: string;
    updatedAt: string;
    slug: string;
    synopsis: string;
    description: string;
    coverImageTopOffset: number;
    titles: Titles;
    canonicalTitle: string;
    abbreviatedTitles: string[];
    averageRating: string;
    ratingFrequencies: RatingFrequencies;
    userCount: number;
    favoritesCount: number;
    startDate: string;
    endDate: string;
    nextRelease: any;
    popularityRank: number;
    ratingRank: number;
    ageRating: "G" | "PG" | "R" | "R18";
    ageRatingGuide: string;
    subtype: string;
    status: string;
    tba: any;
    posterImage: ImageData;
    coverImage: ImageData;
    episodeCount: number;
    episodeLength: number;
    totalLength: number;
    youtubeVideoId: string;
    showType: string;
    nsfw: boolean;
  };

  relationships: {
    genres: Relationship;
    categories: Relationship;
    castings: Relationship;
    installments: Relationship;
    mappings: Relationship;
    reviews: Relationship;
    mediaRelationships: Relationship;
    characters: Relationship;
    staff: Relationship;
    productions: Relationship;
    quotes: Relationship;
    episodes: Relationship;
    streamingLinks: Relationship;
    animeProductions: Relationship;
    animeCharacters: Relationship;
    animeStaff: Relationship;
  };
}

export const DEFAULT_ANIME_IN_PREVIEW: KitsuAnimeData = {
  id: "11",
  type: "anime",
  links: {
    self: "https://kitsu.io/api/edge/anime/11",
  },
  attributes: {
    createdAt: "2013-02-20T16:00:24.797Z",
    updatedAt: "2022-12-13T18:00:15.425Z",
    slug: "naruto",
    synopsis:
      "Moments prior to Naruto Uzumaki's birth, a huge demon known as the Kyuubi, the Nine-Tailed Fox, attacked Konohagakure, the Hidden Leaf Village, and wreaked havoc. In order to put an end to the Kyuubi's rampage, the leader of the village, the Fourth Hokage, sacrificed his life and sealed the monstrous beast inside the newborn Naruto.\nNow, Naruto is a hyperactive and knuckle-headed ninja still living in Konohagakure. Shunned because of the Kyuubi inside him, Naruto struggles to find his place in the village, while his burning desire to become the Hokage of Konohagakure leads him not only to some great new friends, but also some deadly foes.\n[Written by MAL Rewrite]",
    description:
      "Moments prior to Naruto Uzumaki's birth, a huge demon known as the Kyuubi, the Nine-Tailed Fox, attacked Konohagakure, the Hidden Leaf Village, and wreaked havoc. In order to put an end to the Kyuubi's rampage, the leader of the village, the Fourth Hokage, sacrificed his life and sealed the monstrous beast inside the newborn Naruto.\nNow, Naruto is a hyperactive and knuckle-headed ninja still living in Konohagakure. Shunned because of the Kyuubi inside him, Naruto struggles to find his place in the village, while his burning desire to become the Hokage of Konohagakure leads him not only to some great new friends, but also some deadly foes.\n[Written by MAL Rewrite]",
    coverImageTopOffset: 209,
    titles: {
      en: "Naruto",
      en_jp: "Naruto",
      ja_jp: "ナルト",
    },
    canonicalTitle: "Naruto",
    abbreviatedTitles: ["NARUTO"],
    averageRating: "79.93",
    ratingFrequencies: {
      "2": "685",
      "3": "5",
      "4": "520",
      "5": "17",
      "6": "542",
      "7": "32",
      "8": "2580",
      "9": "57",
      "10": "3032",
      "11": "112",
      "12": "6590",
      "13": "336",
      "14": "18061",
      "15": "768",
      "16": "14049",
      "17": "1054",
      "18": "9188",
      "19": "505",
      "20": "25916",
    },
    userCount: 107919,
    favoritesCount: 3017,
    startDate: "2002-10-03",
    endDate: "2007-02-08",
    nextRelease: null,
    popularityRank: 42,
    ratingRank: 651,
    ageRating: "PG",
    ageRatingGuide: "Teens 13 or older",
    subtype: "TV",
    status: "finished",
    tba: null,
    posterImage: {
      tiny: "https://media.kitsu.io/anime/11/poster_image/tiny-47d378c5faf258491fd1a2025fc1507c.jpeg",
      large:
        "https://media.kitsu.io/anime/11/poster_image/large-5e901605066f7f2727f49d01859bbfe4.jpeg",
      small:
        "https://media.kitsu.io/anime/11/poster_image/small-db4debb693482e09f5d9615864b99b3d.jpeg",
      medium:
        "https://media.kitsu.io/anime/11/poster_image/medium-410cee5d114a5f70d69e795755e8348a.jpeg",
      original:
        "https://media.kitsu.io/anime/11/poster_image/4512d403727b2a19a6eb7e7a959be0c3.jpg",
      meta: {
        dimensions: {
          tiny: {
            width: 110,
            height: 156,
          },
          large: {
            width: 550,
            height: 780,
          },
          small: {
            width: 284,
            height: 402,
          },
          medium: {
            width: 390,
            height: 554,
          },
        },
      },
    },
    coverImage: {
      tiny: "https://media.kitsu.io/anime/cover_images/11/tiny.jpg",
      large: "https://media.kitsu.io/anime/cover_images/11/large.jpg",
      small: "https://media.kitsu.io/anime/cover_images/11/small.jpg",
      original: "https://media.kitsu.io/anime/cover_images/11/original.jpg",
      meta: {
        dimensions: {
          tiny: {
            width: 840,
            height: 200,
          },
          large: {
            width: 3360,
            height: 800,
          },
          small: {
            width: 1680,
            height: 400,
          },
        },
      },
    },
    episodeCount: 220,
    episodeLength: 23,
    totalLength: 5060,
    youtubeVideoId: "j2hiC9BmJlQ",
    showType: "TV",
    nsfw: false,
  },
  relationships: {
    genres: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/genres",
        related: "https://kitsu.io/api/edge/anime/11/genres",
      },
    },
    categories: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/categories",
        related: "https://kitsu.io/api/edge/anime/11/categories",
      },
    },
    castings: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/castings",
        related: "https://kitsu.io/api/edge/anime/11/castings",
      },
    },
    installments: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/installments",
        related: "https://kitsu.io/api/edge/anime/11/installments",
      },
    },
    mappings: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/mappings",
        related: "https://kitsu.io/api/edge/anime/11/mappings",
      },
    },
    reviews: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/reviews",
        related: "https://kitsu.io/api/edge/anime/11/reviews",
      },
    },
    mediaRelationships: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/media-relationships",
        related: "https://kitsu.io/api/edge/anime/11/media-relationships",
      },
    },
    characters: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/characters",
        related: "https://kitsu.io/api/edge/anime/11/characters",
      },
    },
    staff: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/staff",
        related: "https://kitsu.io/api/edge/anime/11/staff",
      },
    },
    productions: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/productions",
        related: "https://kitsu.io/api/edge/anime/11/productions",
      },
    },
    quotes: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/quotes",
        related: "https://kitsu.io/api/edge/anime/11/quotes",
      },
    },
    episodes: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/episodes",
        related: "https://kitsu.io/api/edge/anime/11/episodes",
      },
    },
    streamingLinks: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/streaming-links",
        related: "https://kitsu.io/api/edge/anime/11/streaming-links",
      },
    },
    animeProductions: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/anime-productions",
        related: "https://kitsu.io/api/edge/anime/11/anime-productions",
      },
    },
    animeCharacters: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/anime-characters",
        related: "https://kitsu.io/api/edge/anime/11/anime-characters",
      },
    },
    animeStaff: {
      links: {
        self: "https://kitsu.io/api/edge/anime/11/relationships/anime-staff",
        related: "https://kitsu.io/api/edge/anime/11/anime-staff",
      },
    },
  },
};

// * --- Jikan API --- * //
interface JikanImage {
  image_url: string;
  small_image_url: string;
  large_image_url: string;
}

interface JikanContact {
  mal_id: number;
  type: string;
  name: string;
  url: string;
}

interface JikanTime {
  day: number;
  month: number;
  year: number;
}

export interface JikanAnimeData {
  mal_id: number;
  url: string;
  images: { jpg: JikanImage; webp: JikanImage };
  trailer: { youtube_id: string; url: string; embed_url: string };
  approved: true;
  titles: { type: string; title: string }[];
  title: string;
  title_english: string;
  title_japanese: string;
  title_synonyms: string[];
  type: "TV" | "Movie" | "Ova" | "Special" | "ONA" | "Music";
  source: string;
  episodes: number;
  status: "Finished Airing" | "Currently Airing" | "Not Yet Aired";
  airing: true;
  aired: {
    from: string;
    to: string;
    prop: { from: JikanTime; to: JikanTime; string: string };
  };
  duration: string;
  rating:
    | "G - All Ages"
    | "PG - Children"
    | "PG-13 - Teens 13 or older"
    | "R - 17+ (violence & profanity)"
    | "R+ - Mild Nudity"
    | "Rx - Hentai";
  score: number;
  scored_by: number;
  rank: number;
  popularity: number;
  members: number;
  favorites: number;
  synopsis: string;
  background: string;
  season: "summer" | "fall" | "winter" | "spring";
  year: number;
  broadcast: { day: string; time: string; timezone: string };
  producers: JikanContact[];
  licensors: JikanContact[];
  studios: JikanContact[];
  genres: JikanContact[];
  explicit_genres: JikanContact[];
  themes: JikanContact[];
  demographics: JikanContact[];
}
