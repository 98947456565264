import FadeIn from "react-fade-in";
import Lottie from "react-lottie";

import * as loading from "assets/animationFile/117-progress-bar.json";
import * as doneLoading from "assets/animationFile/433-checked-done.json";
import "./LoadingComponent.scss";
// import { ninjaLoading } from "assets/animationFile";
// import * as ninjaLoading from "assets/animationFile/19671-task-ninja-black-cute.json";
// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: ninjaLoading.default,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

const defaultOptions = {
  loop: true,
  autoplay: true,
  // @ts-ignore
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: false,
  autoplay: true,
  // @ts-ignore
  animationData: doneLoading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface LoadingProp {
  className?: string;
  message?: string;
  isLoading?: boolean;
}

export const LoadingComponent = ({
  className,
  message = "Loading",
  isLoading = true,
}: LoadingProp) => {
  return (
    <FadeIn className={`loading-component ${className}`}>
      <div className="loading-text-container">
        <h1>{message}</h1>
      </div>
      {isLoading ? (
        <Lottie options={defaultOptions} height={80} width={80} />
      ) : (
        <Lottie options={defaultOptions2} height={80} width={80} />
      )}
    </FadeIn>
  );
};
