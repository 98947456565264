import { constructingImg } from "assets/images";
import {
  apheleiaPreviewPic,
  capstonePic,
  chempediaPic,
  insiteWeb,
  instaSalonPic,
  msqPic,
  tdDTPic,
  teddyFighterXDPic,
  wctTigardPreviewPic,
  whenTheDreamEndPreviewPic,
} from "assets/projects";
import { ProjectI18NMap } from "./i18n";

interface ProjectProps {
  title: string;
  showProject: boolean;
  liveStatus: "alive" | "constructing" | "down";
  tagList: string[];
  techstack: { icon: [string, string]; displayName: string }[];
  description: readonly (keyof ProjectI18NMap["card"]["description"])[];
  href?: string;
  imgSrc: string;
}

export const professionalProjects: ProjectProps[] = [
  {
    title: "InstaSalon",
    showProject: true,
    liveStatus: "alive",
    tagList: ["Fullstack", "Firebase", "QR Scheduling", "SaaS"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "google"], displayName: "Firebase" },
      { icon: ["far", "qrcode"], displayName: "QR Scheduling" },
    ],
    description: [
      "saasPlatform",
      "reactStack",
      "backendTeamLead",
      "soleDesigner",
      "cssStack",
    ] as const,
    href: "https://dev.instasalon.beauty/",
    imgSrc: instaSalonPic,
  },
  {
    title: "TDN Quiz Platform",
    showProject: true,
    liveStatus: "alive",
    tagList: ["Web App", "Fullstack", "Firebase"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "google"], displayName: "Firebase" },
    ],
    description: [
      "quizPlatScale",
      "reactStack",
      "soleDeveloper",
      "soleDesigner",
      "cssStack",
      "dedicatedToHS",
    ] as const,
    href: "https://tdn-quizeria.web.app/",
    // href: "https://msq-dev-v2.web.app/login",
    imgSrc: msqPic,
  },
  {
    title: "Insite Agent-based modelling",
    showProject: true,
    liveStatus: "alive",
    tagList: ["Web App", "ABM", "Cloud"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "google"], displayName: "GCP" },
      { icon: ["fab", "python"], displayName: "Python" },
    ],
    description: [
      "insiteEnergy",
      "gcpStackCICD",
      "reactStack",
      "cssStack",
      "pythonBackendStack",
      "principalInvestigator",
    ],
    href: "https://insite-abm.web.app/",
    imgSrc: insiteWeb,
  },
  {
    title: "Corona Hub",
    showProject: true,
    liveStatus: "alive",
    tagList: ["Web App", "Corona Health"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "google"], displayName: "Firebase" },
      { icon: ["far", "comment-smile"], displayName: "IBM Chatbot" },
    ],
    description: [
      "reactStack",
      "capstone",
      "capstoneTechlead",
      "capstoneDetail",
      "fromScratch",
      "cssStack",
      "restful",
    ],
    href: "https://coronahub.web.app/",
    imgSrc: capstonePic,
  },
  {
    title: "Duysterz Shoes",
    showProject: true,
    liveStatus: "constructing",
    tagList: ["Web App", "E-commerce"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "python"], displayName: "Django" },
      { icon: ["far", "cart-plus"], displayName: "Saleor" },
    ],
    description: ["saleor", "ecommerce", "pwa", "adminDashboard"],
    href: "https://duysterz-shoes-store.web.app/",
    imgSrc: constructingImg,
  },
  {
    title: "DT TD",
    showProject: true,
    liveStatus: "constructing",
    tagList: ["Mobile App", "AR"],
    techstack: [
      { icon: ["fab", "unity"], displayName: "Unity 3D" },
      { icon: ["far", "vr-cardboard"], displayName: "AR Foundation" },
      { icon: ["far", "code"], displayName: "C#" },
    ],
    description: ["unityARStack", "android", "dtTD", "Thang"],
    href: "",
    imgSrc: tdDTPic,
  },
  {
    title: "Apheleia Web",
    showProject: true,
    liveStatus: "alive",
    tagList: ["Web App"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "mailchimp"], displayName: "Mailchimp" },
      { icon: ["fab", "google"], displayName: "Firebase" },
      { icon: ["fab", "aws"], displayName: "Amazon Web Service" },
    ],
    description: ["reactStack", "mailchimp", "cssStack", "Thy"],
    href: "https://master.d1mu6qk0op5jdp.amplifyapp.com/",
    imgSrc: apheleiaPreviewPic,
  },
  {
    title: "WCT Tigard Mobile App",
    showProject: true,
    liveStatus: "down",
    tagList: ["Mobile App", "Taekwondo"],
    techstack: [
      { icon: ["fab", "react"], displayName: "React" },
      { icon: ["fab", "react"], displayName: "React Native" },
      { icon: ["fab", "google"], displayName: "Firebase" },
    ],
    description: [
      "reactStack",
      "ios",
      "wct",
      "adminDashboard",
      "mobileCSSStack",
      "Thang",
    ],
    href: "",
    imgSrc: wctTigardPreviewPic,
  },
];

export const funProjects: ProjectProps[] = [
  {
    title: "Chempedia",
    showProject: true,
    liveStatus: "down",
    tagList: ["Mobile App", "Window Phone", "Chemistry"],
    techstack: [
      { icon: ["fab", "windows"], displayName: "Window Phone" },
      { icon: ["far", "code"], displayName: "C#" },
      { icon: ["far", "atom-alt"], displayName: "Chemistry" },
    ],
    description: ["forStudent", "chempediaStack", "windowPhone", "Viet"],
    href: "",
    imgSrc: chempediaPic,
  },
  {
    title: "Teddy Fighter xD",
    showProject: true,
    liveStatus: "constructing",
    tagList: ["Topdown Shooter", "Unity"],
    techstack: [
      { icon: ["fab", "unity"], displayName: "Unity 3D" },
      { icon: ["far", "code"], displayName: "C#" },
      { icon: ["far", "palette"], displayName: "Pixel Art" },
    ],
    description: ["pixel", "topdown"],
    href: "",
    imgSrc: teddyFighterXDPic,
  },
  {
    title: "When the Dream Ends",
    showProject: true,
    liveStatus: "down",
    tagList: ["RPG", "Unity"],
    techstack: [
      { icon: ["fab", "unity"], displayName: "Unity 3D" },
      { icon: ["far", "code"], displayName: "C#" },
      { icon: ["far", "palette"], displayName: "Pixel Art" },
    ],
    description: ["pixel", "rpg"],
    href: "",
    imgSrc: whenTheDreamEndPreviewPic,
  },
];
