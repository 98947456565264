export const removeSpace = (str: string) => str.replace(/\s/g, "");

export const getTranslationString = (str: string) =>
  str.charAt(0).toLowerCase() + removeSpace(str).slice(1);

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getErrorString = (action: string, error: any) =>
  `Error during "${action}" --- ${error}`;

// This would fix modulo bug
//https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
export const mod = (x: number, y: number) => ((x % y) + y) % y;

export const mapNumberRange = (
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
  curVal: number
) => ((curVal - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

export const getRandomHexColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const getRandomDirection = () => getRandomInt(2) * 2 - 1; // [-1, 1]
