import dayjs from "dayjs";
import { Action, action, Computed, computed } from "easy-peasy";

import {
  commandListDispatcher,
  CommandListType,
  initialObject,
  isShowCommandArgType,
  TerminalOutputObject,
  TerminalCommandStatus,
  isValidCommand,
} from "./util";

export interface ResumeModel {
  // * State ---------------------------
  outputList: TerminalOutputObject[];
  numError: Computed<ResumeModel, number>;
  numWarning: Computed<ResumeModel, number>;

  // * Actions -------------------------
  handleUserInput: Action<ResumeModel, { value: string }>;
}

export const resume: ResumeModel = {
  // States ---------------------
  // The first one is a help command
  outputList: [initialObject],
  numError: computed(
    (state) =>
      state.outputList.filter((output) => output.status === "error").length,
  ),
  numWarning: computed(
    (state) =>
      state.outputList.filter((output) => output.status === "warning").length,
  ),

  // Actions ---------------------
  handleUserInput: action((state, userInput) => {
    // Break down user input which is a strings delimited by spaces
    const input = userInput.value.split(" ");
    const command = input[0];
    const argList = input.splice(1);
    const argListString = argList.join(",");

    const dayjsTime = dayjs();
    if (command === "clrscr") {
      state.outputList = [
        {
          dayjsTime,
          commandType: "clrscr",
          command: "Hello world!",
          status: "success",
        },
      ];
      return;
    }

    // Check for syntax error and unknown command
    const baseObj = { dayjsTime, command, argListString };

    let newOutput: TerminalOutputObject = {
      ...baseObj,
      commandType: "unknown",
      status: "error",
    };

    if (isValidCommand(command)) {
      // Match against the command list
      switch (command) {
        case "show":
          if (isShowCommandArgType(argList)) {
            const { status: showStatus, data: showData } =
              commandListDispatcher["show"](argList);
            newOutput = {
              ...baseObj,
              status: showStatus,
              commandType: "show",
              resultData: showData,
            };
          } else {
            newOutput = {
              ...baseObj,
              status: "warning",
              commandType: "show",
              resultData: "argError",
            };
          }
          break;
        case "help":
          const { data: helpData, type: helpType } =
            commandListDispatcher[command]();
          newOutput = {
            ...baseObj,
            status: "success",
            commandType: "help",
            resultData: { type: helpType, data: helpData },
          };
          break;
        case "hello":
          const { data: helloData } = commandListDispatcher["hello"](argList);
          newOutput = {
            ...baseObj,
            status: "success",
            commandType: "hello",
            resultData: { data: helloData },
          };
          break;
      }
    } else if (!command) {
      // Empty command string
      newOutput = {
        ...baseObj,
        commandType: "unknown",
        status: "error",
        errorCode: "cannotBeEmpty",
      };
    }

    state.outputList.push(newOutput);
  }),
};
