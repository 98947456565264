import { Action, action, ActionOn, actionOn } from "easy-peasy";
import { Howl } from "howler";

const makeNewSong = (song: string, volume: number = 1) => {
  const newSong = new Howl({
    src: [song],
    autoplay: false,
    loop: true,
    volume,
  });

  newSong.on("fade", () => {
    // Pause on fade out
    if (newSong.volume() <= 0) {
      newSong.pause();
    }
  });

  return newSong;
};

export interface RadioModel {
  isPlaying: boolean;

  curSong: Howl | undefined;
  curSongTitle: string;
  songId: number | undefined;

  curVolume: number;

  setSong: Action<RadioModel, string>;

  setVolume: Action<RadioModel, number>;
  onSetVolume: ActionOn<RadioModel>;

  togglePlay: Action<RadioModel>;
}

const MUSIC_FADE_TIMER: number = 3000; // In seconds
const radio: RadioModel = {
  // * State ---------------------------------
  isPlaying: false,

  curSong: undefined,
  curSongTitle: "",
  songId: undefined,

  curVolume: 1,

  // * Actions -------------------------------
  setSong: action((state, path) => {
    const newSong = makeNewSong(path, state.curVolume);
    const songName = path.split("/").pop()?.split(".")[0] ?? "Unknown";

    state.curSong = newSong;
    state.curSongTitle = songName;
  }),

  setVolume: action((state, newVolume) => {
    state.curVolume = newVolume;
  }),
  onSetVolume: actionOn(
    (actions) => actions.setVolume,
    (state, target) => {
      if (state.isPlaying) {
        state.curSong?.volume(target.payload);
      }
    },
  ),

  togglePlay: action((state) => {
    // Play the music!
    if (state.isPlaying) {
      // Fade out first then pause
      state.curSong?.fade(state.curSong?.volume(), 0, MUSIC_FADE_TIMER * 0.3);
    } else {
      // Start the song
      if (!state.songId) {
        // Make sure we only once song instance at a time
        const newSongId = state.curSong?.play();
        state.songId = newSongId;
      } else {
        state.curSong?.play(state.songId);
      }
      // Then fade in
      state.curSong?.fade(
        state.curSong?.volume(),
        state.curVolume,
        MUSIC_FADE_TIMER,
      );
    }

    state.isPlaying = !state.isPlaying;
  }),
};

export default radio;
