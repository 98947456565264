import { Action, action, Computed, computed } from "easy-peasy";

import { CrosswordUIData } from "data/crosswordInfo";
import { Coord } from "data/coordInfo";

type UIData = CrosswordUIData | null;
type CoordInfoData = Coord | null;
type ClueInfo = {
  clue: string;
  minCoord: CoordInfoData;
  maxCoord: CoordInfoData;
};
type ClueMap = Record<number, string>;

const findBoundCell = (
  direction: keyof Coord,
  coord: Coord,
  clueMap: ClueMap,
  uiData: CrosswordUIData
): { minCoord: Coord; maxCoord: Coord } => {
  const minCoord = { ...coord };
  const maxCoord = { ...coord };
  // Go backward until we find the first clue or block
  for (; minCoord[direction] >= 0; minCoord[direction]--) {
    if (uiData.matrixData[minCoord.y][minCoord.x].content === ".") {
      // ! This shouldn't happen at all because it should find a clue before hitting a block
      // Hit a block
      minCoord[direction]++; // Move forward 1 cell
      break;
    }

    const cellNum = uiData.matrixData[minCoord.y][minCoord.x].number;
    if (clueMap[cellNum]) {
      break;
    }
  }

  // Go forward until we find the block or glue
  const maxMove = uiData.boundary[direction];
  // Go backward until we find the first clue or block
  for (; maxCoord[direction] <= maxMove; maxCoord[direction]++) {
    // ! The assumption is there can't be a clue cell on the way moving forward
    if (uiData.matrixData[maxCoord.y][maxCoord.x].content === ".") {
      // Hit a block
      maxCoord[direction]--; // Move back
      break;
    }
  }

  return { minCoord, maxCoord };
};

const getClosetClue = (
  direction: keyof Coord,
  coord: Coord,
  clueMap: ClueMap,
  uiData: CrosswordUIData
): ClueInfo => {
  const { minCoord, maxCoord } = findBoundCell(
    direction,
    coord,
    clueMap,
    uiData
  );

  const returnVal = { clue: "", minCoord, maxCoord };
  if (minCoord) {
    const cellNum = uiData.matrixData[minCoord.y][minCoord.x].number;
    returnVal.clue = clueMap[cellNum] || "";
  }
  return returnVal;
};

const findClue = (uiData: UIData, focusCoord: CoordInfoData) => {
  let across: ClueInfo = { clue: "", minCoord: null, maxCoord: null };
  let down: ClueInfo = { clue: "", minCoord: null, maxCoord: null };

  if (uiData && focusCoord) {
    across = getClosetClue("x", focusCoord, uiData.clueList.across, uiData);
    down = getClosetClue("y", focusCoord, uiData.clueList.down, uiData);
  }

  return { across, down };
};

export interface CrosswordModel {
  // * State
  uiData: UIData;
  focusCoord: CoordInfoData;
  clueInfo: Computed<
    CrosswordModel,
    {
      across: ClueInfo;
      down: ClueInfo;
    }
  >;

  // * Actions
  setUIData: Action<CrosswordModel, UIData>;
  setFocusCoord: Action<CrosswordModel, CoordInfoData>;
}

export const crossword: CrosswordModel = {
  uiData: null,
  focusCoord: null,
  clueInfo: computed((state) => findClue(state.uiData, state.focusCoord)),

  setUIData: action((state, uiData) => {
    state.uiData = uiData;
  }),
  setFocusCoord: action((state, focusCoord) => {
    state.focusCoord = focusCoord;
  }),
};
