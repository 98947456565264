import axios from "axios";
import {
  DEFAULT_ANIME_IN_PREVIEW,
  JikanAnimeData,
  KitsuAnimeData,
} from "data/anime-api";
import {
  Action,
  action,
  Computed,
  computed,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { getErrorString } from "utils";

interface ShortAnimeData {
  id: string | number;
  name: string;
  coverUrl: string;
  link: string;
}

// TODO: replace this URL with v4 https://docs.api.jikan.moe/#section/Information
// v3 is deprecated
const JIKAN_BASE_URL = "https://api.jikan.moe/v3/search/anime";

const KITSU_BASE_URL = "https://kitsu.io/api/edge/anime";

export interface AnimeAPIModel {
  // * State --------------------
  animeList: KitsuAnimeData[];
  previewListWithImage: Computed<AnimeAPIModel, ShortAnimeData[]>;
  bigPreviewAnime: ShortAnimeData;
  animeInPreview: KitsuAnimeData;

  searchResult: JikanAnimeData[];
  isSearching: boolean;

  // * Actions ------------------
  addAnimeList: Action<AnimeAPIModel, KitsuAnimeData[]>;
  selectAnimeForBigPreview: Action<AnimeAPIModel, ShortAnimeData>;
  setPreviewAnime: Action<AnimeAPIModel, KitsuAnimeData>;

  setIsSearching: Action<AnimeAPIModel, boolean>;
  setSearchResult: Action<AnimeAPIModel, JikanAnimeData[]>;

  // * Thunks -------------------
  fetchAnime: Thunk<AnimeAPIModel, { requiredLength: number }>;
  getAnimeInfo: Thunk<AnimeAPIModel, { id: string | number; link: string }>;
  searchAnime: Thunk<AnimeAPIModel, { limit: number; animeName: string }>;

  // * Listeners
  onChoosePreviewAnime: ThunkOn<AnimeAPIModel>;
}

export const animeAPI: AnimeAPIModel = {
  animeList: [],
  previewListWithImage: computed((state) =>
    state.animeList
      .filter((anime) => anime?.attributes?.coverImage)
      .map((anime) => ({
        id: anime.id,
        name: anime.attributes.canonicalTitle,
        coverUrl: anime.attributes.coverImage.original,
        link: anime.links.self,
      })),
  ),
  bigPreviewAnime: {
    id: 11,
    name: "Naruto",
    coverUrl: "https://media.kitsu.io/anime/cover_images/11/original.jpg",
    link: "https://private-anon-1a3222f18a-kitsu.apiary-proxy.com/api/edge/anime/11",
  },
  animeInPreview: DEFAULT_ANIME_IN_PREVIEW,
  searchResult: [],
  isSearching: false,

  //Actions
  addAnimeList: action((state, animeList) => {
    state.animeList.push(...animeList);
  }),
  selectAnimeForBigPreview: action((state, payload) => {
    state.bigPreviewAnime = { ...payload };
  }),
  setPreviewAnime: action((state, payload) => {
    state.animeInPreview = payload;
  }),
  setIsSearching: action((state, payload) => {
    state.isSearching = payload;
  }),
  setSearchResult: action((state, payload) => {
    state.searchResult = payload;
  }),

  // Async thunks!
  /**
   * Summary. Fetch anime list given a url (should be from Kitsu API)
   *
   * @param {string}          url             The url for the next fetch.
   * @param {int}             requiredLength  The total number of anime to fetch
   */
  fetchAnime: thunk((actions, payload, { getState }) => {
    axios
      .get<{
        data: KitsuAnimeData[];
        links: { first: string; last: string; next: string };
        meta: { count: number };
      }>(KITSU_BASE_URL)
      .then(function (response) {
        // Check how many element we will get from this fetch
        const curLength = getState().animeList.length;
        const requiredLength = payload.requiredLength;
        const diffLength = requiredLength - curLength;

        // Then get that many data
        const resAnimeList = response.data.data;
        const addData = resAnimeList.slice(
          0,
          Math.min(resAnimeList.length, diffLength),
        );

        // Add to animeList
        actions.addAnimeList(addData);

        // If we need to fetch more, then fetch more
        if (getState().animeList.length < requiredLength) {
          const url = response.data.links.next;

          if (!url) {
            return null;
          }

          actions.fetchAnime({ requiredLength });
        }
      })
      .catch(function (error) {
        // handle error
        console.error(getErrorString("Fetch Anime List", error));
      });
  }),

  /**
   * Summary. Fetch anime given a url (should be from Kitsu API)
   * @param {map}             animeInfo        The info of anime to fetch
   *  @param {string}          url             The url for the next fetch.
   *  @param {int}             requiredLength  The total number of anime to fetch
   */
  getAnimeInfo: thunk(async (actions, payload, { getState }) => {
    // First search in the anime list
    const curAnimeList = getState().animeList;
    let animeInfo = curAnimeList.find((anime) => anime.id === payload.id);

    // Then fetch if not found
    if (!animeInfo) {
      console.warn("Can't find Anime in current list, fetching...");
      try {
        animeInfo = (await axios.get<{ data: KitsuAnimeData }>(payload.link))
          .data.data;
      } catch (error) {
        console.error(getErrorString("Search for anime", error));
      }
    }

    if (animeInfo) {
      actions.setPreviewAnime(animeInfo);
    } else {
      console.error("Can't find anime with id: " + payload.id);
    }
  }),

  /**
   * Summary. Fetch anime given a url (should be from Jikan API)
   * @param {string}           animeName       The name of the anime to search
   * @param {int}              limit           Used to limit the number of search result
   */
  searchAnime: thunk(async (actions, { limit, animeName }) => {
    // TODO: migrate this
    // actions.setIsSearching(true);
    // try {
    //   console.info(`Searching for: ${animeName} with limit: ${limit}`);
    //   const searchResult = (
    //     await axios.get<{ results: DetailAnimeData[] }>(
    //       `${ANIME_QUERY_API_BASE_URL}?q=${animeName}&limit=${limit}`,
    //     )
    //   ).data.results;
    //   console.info(
    //     `Got ${searchResult.length} result from searching anime with key ${animeName}`,
    //   );
    //   // Make sure to filter out hentai anime :)
    //   actions.setSearchResult(
    //     searchResult.filter(({ rated }) => rated !== "Rx"),
    //   );
    // } catch (error) {
    //   console.error(getErrorString("Search for anime", error));
    // }
    // actions.setIsSearching(true);
  }),

  // Listener
  // Listen to new big preview anime
  onChoosePreviewAnime: thunkOn(
    (actions) => actions.selectAnimeForBigPreview,
    (actions, target) => {
      const { id, link } = target.payload;
      actions.getAnimeInfo({ id, link });
    },
  ),
};

export default animeAPI;
