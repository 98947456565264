import "./wdyr";

import { StoreProvider } from "easy-peasy";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { store } from "store";
import { initializeIconList } from "utils/iconInitializer";
import App from "./App";
import { initializeI18N } from "./loaders/i18n";
import * as serviceWorker from "./serviceWorker";

// Intialize icon
initializeIconList();

// Intialize i18n
initializeI18N();

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBgfX-M0I185m8vz3CkAxTDQu3nLLbOEPk",
  authDomain: "duc-portfolio-v2.firebaseapp.com",
  databaseURL: "https://duc-portfolio-v2.firebaseio.com",
  projectId: "duc-portfolio-v2",
  storageBucket: "duc-portfolio-v2.appspot.com",
  messagingSenderId: "865720250996",
  appId: "1:865720250996:web:eabc47e6dec320a0d5d721",
  measurementId: "G-NJR7Z2C836",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Suspense fallback="Loading...">
        <App />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
