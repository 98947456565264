import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import cx from "classnames";

import "./Sidebar.scss";

import { useStoreActions, useStoreState } from "store";

import { Icon } from ".";
import { breakpointInfo, mainRoute, ThemeType } from "data";
import { logo } from "assets/logo";
import { useThemeColor } from "components/hooks";

const languageList = ["vi", "en"] as const;
const themeList: Array<{
  iconName: string;
  theme: ThemeType;
}> = [
  { iconName: "moon", theme: "theme-dark" },
  { iconName: "sun", theme: "theme-light" },
];
const networkList = [
  {
    path: "https://www.linkedin.com/in/duc-phan-576846132/",
    name: "LinkedIn",
    icon: ["fab", "linkedin"],
    lightColor: "#0077B5",
    darkColor: "#0595e2",
  },
  {
    path: "https://github.com/ptmdmusique",
    name: "GitHub",
    icon: ["fab", "github"],
    lightColor: "#000",
    darkColor: "#FFFFFE",
  },
  {
    path: "https://portfolio.ptmdmusique.now.sh/",
    name: "Portfolio v1",
    icon: ["far", "user-ninja"],
    lightColor: "#FFFFFE",
    darkColor: "#FFFFFE",
  },
  {
    path: "https://welcometotheducduchy.weebly.com/",
    name: "Portfolio v0",
    icon: ["far", "torii-gate"],
    lightColor: "#a91e29",
    darkColor: "#ff4958",
  },
] as const;

interface MoreMenuProp {
  isShowing: boolean;
  sidebarOpen: boolean;
}

const MoreMenu = ({ isShowing, sidebarOpen }: MoreMenuProp) => {
  const { t, i18n } = useTranslation("common");
  const changeLanguage = useStoreActions(
    (actions) => actions.i18n.changeLanguage,
  );

  const curTheme = useStoreState((state) => state.theme.theme);
  const changeTheme = useStoreActions((actions) => actions.theme.changeTheme);

  return (
    <div
      className={cx(
        "moremenu",
        { "moremenu-show": isShowing },
        { "moremenu-open": sidebarOpen },
      )}
    >
      <div className="content">
        <section>
          <h3>{t("sidebar.networking")}</h3>
          {networkList.map((network, index) => (
            <a
              key={index}
              href={network.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                className="moremenu-brand-icon fa-fw"
                icon={network.icon}
                style={{
                  color:
                    curTheme === "theme-light"
                      ? network.lightColor
                      : network.darkColor,
                }}
              />
              <p>{network.name}</p>
            </a>
          ))}
        </section>

        <section>
          <h3>{t("sidebar.changeLanguage")}</h3>
          {languageList.map((language, index) => (
            <button
              key={index}
              onClick={() => changeLanguage({ i18n, newLanguage: language })}
              className={cx({ "moremenu-active": i18n.language === language })}
            >
              <p>{language.toUpperCase()}</p>
              <p>{t(`language.${language}`)}</p>
            </button>
          ))}
        </section>

        <section>
          <h3>{t("sidebar.themify")}</h3>
          {themeList.map((theme, index) => (
            <button
              key={index}
              onClick={() => changeTheme(theme.theme)}
              className={cx({ "moremenu-active": curTheme === theme.theme })}
            >
              <Icon icon={["far", theme.iconName]} className="fa-fw" />
              <p>{t(`theme.${theme.theme}`)}</p>
            </button>
          ))}
        </section>
      </div>
    </div>
  );
};

interface SidebarItemProp {
  text: string;
  icon: [string, string] | readonly [string, string];
  iconClassName?: string;
}
const SidebarItem = ({ text, icon, iconClassName }: SidebarItemProp) => {
  return (
    <>
      <Icon icon={icon} className={cx(iconClassName, "icon", "fa-fw")} />
      <p>{text}</p>
    </>
  );
};

interface CustomNavLinkProp extends SidebarItemProp {
  path: string;
  colorCode: string;
}
const CustomNavLink = ({
  path,
  text,
  icon,
  iconClassName,
  colorCode,
}: CustomNavLinkProp) => {
  const color = useThemeColor(colorCode);
  const isLg = useMedia(`(min-width: ${breakpointInfo.lg}px)`);
  const isActive = useLocation().pathname === path;

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cx("item-container", {
          "item-container-active": isActive,
        })
      }
      style={{
        color,
        borderTopColor: cx({ [color]: !isLg && isActive }),
        borderRightColor: cx({ [color]: isLg && isActive }),
      }}
    >
      <SidebarItem icon={icon} text={text} iconClassName={iconClassName} />
    </NavLink>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const color = useThemeColor("text-primary");
  const isLg = useMedia(`(min-width: ${breakpointInfo.lg}px)`);

  const [moreMenuShown, setMoreMenuShown] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <nav className={cx("sidebar", { "sidebar-open": sidebarOpen })}>
        <div className={cx("logo", { "logo-open": sidebarOpen })}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/duc-phan-576846132/"
          >
            <img src={logo} alt="logo" />
          </a>
        </div>

        <ul className="content">
          {mainRoute.map(({ icon, path, displayName }, key) => (
            <CustomNavLink
              {...{ icon, path, key }}
              text={t(
                `route.${
                  displayName.toLowerCase() as Lowercase<typeof displayName>
                }`,
              )}
              colorCode={displayName.toLowerCase()}
            />
          ))}

          <button
            className={cx("item-container", {
              "item-container-active": moreMenuShown,
            })}
            onClick={() => setMoreMenuShown(!moreMenuShown)}
            style={{ color, borderBottomColor: cx({ [color]: !isLg }) }}
          >
            <SidebarItem
              icon={["far", "ellipsis-h"]}
              iconClassName={cx("chevron", { "chevron-open": moreMenuShown })}
              text={t("sidebar.more")}
            />
          </button>
        </ul>

        <div className="close-cta">
          <button
            className="item-container"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            style={{ color, borderRightColor: cx({ [color]: isLg }) }}
          >
            <SidebarItem
              icon={["far", "chevron-right"]}
              text={t("sidebar.close")}
              iconClassName={cx("chevron", { "chevron-open": sidebarOpen })}
            />
          </button>
        </div>
      </nav>

      <MoreMenu isShowing={moreMenuShown} sidebarOpen={sidebarOpen} />
    </>
  );
};

export default Sidebar;
