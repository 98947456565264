import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { notFoundImage } from "assets/images";

interface LazyLoadPresetProp {
  image: { src: string; alt: string };
  wrapperClassName?: string;
  effect?: "blur" | "opacity" | "black-and-white";
  beforeLoad?: () => void;
  afterLoad?: () => void;
}
export const LazyLoadPreset = ({
  image,
  wrapperClassName,
  effect = "blur",
  beforeLoad = () => {},
  afterLoad = () => {},
}: LazyLoadPresetProp) => (
  <LazyLoadImage
    alt={image.alt}
    effect={effect}
    src={image.src}
    onError={(e) => {
      // @ts-ignore
      console.warn(`Can't find image with url: ${e.target.src}`);
      // @ts-ignore
      e.target.src = notFoundImage;
    }}
    beforeLoad={beforeLoad}
    afterLoad={afterLoad}
    wrapperClassName={wrapperClassName || "lazy-load-wrapper"}
  />
);
