export const capstoneAdmin = require("./capstone.png");
export const taekwondoInstructor = require("./instructor2.jpg");
export const insiteCode = require("./insite.png");
export const insiteWeb = require("./insite_2.png")

export const audioVisualizerPic = require("./audio_visualizer_pic.PNG");
export const terminalResumePic = require("./terminalResume.PNG");
export const animeAPIPic = require("./animeAPI.PNG");
export const bubblePic = require("./bubble.PNG");
export const flockingPic = require("./flocking.PNG");

export const msqPic = require("./msq.PNG");
export const tdDTPic = require("./dtTD.PNG");
export const capstonePic = require("./capstone.png");
export const apheleiaPreviewPic = require("./apheleia.PNG");
export const wctTigardPreviewPic = require("./wctTigard_preview.png");
export const instaSalonPic = require("./insta-salon.png");

export const teddyFighterXDPic = require("./TeddyFighter_preview.png");
export const whenTheDreamEndPreviewPic = require("./WhenTheDreamEnds_Preview-min.png");
export const chempediaPic = require("./Chempedia.png");
