import { useState, useEffect } from "react";
import lodash from "lodash";

/* Note
  key: <type>_[light|dark]
  value: color string
 */
// @ts-ignore
import mainTheme from "styles/colorPalette.scss";
// @ts-ignore
import secondaryTheme from "styles/palettes/secondaryPalettes.scss";

import { themeList } from "data";

import { useStoreState } from "store";

const convertThemeMap = (map: object) => {
// First generate an empty result map
  const result: Record<string, Record<string, string>> = {};
  themeList.forEach((theme) => {
    result[theme] = {};
  });

  // Extract theme from the map
  for (let [colorThemeKey, color] of Object.entries(map)) {
    const [colorKey, theme] = colorThemeKey.split("_"); // Split the light and dark
    result[theme][colorKey] = color;
  }

  return result;
};

export const useThemeColor = (colorKey: string) => {
  const { theme } = useStoreState((state) => state.theme);

  const [allColor] = useState(
    lodash.merge(convertThemeMap(secondaryTheme), convertThemeMap(mainTheme))
  );
  const [color, setColor] = useState<string>("#fff");

  useEffect(() => {
    if (theme) {
      setColor(allColor[theme][colorKey]);
    }
  }, [theme, allColor, colorKey]);

  return color;
};
