import cx from "classnames";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { a, useSpring } from "react-spring";
import { useSlider } from "react-use";

import { mapNumberRange } from "utils";
import "./CustomSlider.scss";

interface CustomSliderProp {
  vertical?: boolean;
  defaultValue?: number;
  onChange?: (position: number) => any;
}

const CustomSlider = ({
  vertical = false,
  defaultValue = 0,
  onChange,
}: CustomSliderProp) => {
  const positionText = vertical ? "top" : "left";
  const firstRender = useRef(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const knobRef = useRef<HTMLDivElement | null>(null);

  const { isSliding, value: slideValue } = useSlider(containerRef, {});
  const [{ offset }, setOffset] = useSpring(() => ({ offset: defaultValue }));
  const [maxSlideOffset, setMaxSlideOffset] = useState(0);

  useEffect(() => {
    if (!firstRender.current) {
      onChange?.(slideValue);
      setOffset({ offset: slideValue });
    } else {
      setTimeout(() => {
        // We can't set the default value for `slideValue`
        //  so this is reporting the initial value of 0 back to the parent
        firstRender.current = false;
      }, 10);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideValue]);

  useEffect(() => {
    if (knobRef && containerRef) {
      // Wait until the dom is mounted first or else it will return 0
      setTimeout(() => {
        // We need to calculate the range that the knob slider will slide
        const getWidth = (ref: MutableRefObject<HTMLElement | null>) =>
          ref.current?.getBoundingClientRect()["width"] ?? 0;

        const knobWidth = getWidth(knobRef);
        const containerSize = getWidth(containerRef);
        setMaxSlideOffset(containerSize - knobWidth);
      }, 1000);
    }
  }, [knobRef, containerRef]);

  return (
    <div
      className={cx("custom-slider", { "custom-slider-active": isSliding })}
      ref={containerRef}
    >
      <a.div
        ref={knobRef}
        className={cx("slider-knob", { "slider-knob-vertical": vertical })}
        style={{
          [positionText]: offset.to((value) => {
            return mapNumberRange(0, 1, 0, maxSlideOffset, value);
          }),
        }}
      />
    </div>
  );
};

export default CustomSlider;
