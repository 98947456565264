import animeAPIEnNS from "assets/locales/en/animeAPI.json";
import audioVisualizerEnNS from "assets/locales/en/audioVisualizer.json";
import canvasEnNS from "assets/locales/en/canvas.json";
import commonEnNS from "assets/locales/en/common.json";
import gomojiEnNS from "assets/locales/en/gomoji.json";
import projectEnNS from "assets/locales/en/project.json";
import resumeEnNS from "assets/locales/en/resume.json";
import animeAPIViNS from "assets/locales/vi/animeAPI.json";
import audioVisualizerViNS from "assets/locales/vi/audioVisualizer.json";
import canvasViNS from "assets/locales/vi/canvas.json";
import commonViNS from "assets/locales/vi/common.json";
import gomojiViNS from "assets/locales/vi/gomoji.json";
import projectViNS from "assets/locales/vi/project.json";
import resumeViNS from "assets/locales/vi/resume.json";

import { Environments } from "data/env";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getEnv } from "utils/env";

export const defaultNS = "common";
export const resources = {
  en: {
    common: commonEnNS,
    resume: resumeEnNS,
    audioVisualizer: audioVisualizerEnNS,
    animeAPI: animeAPIEnNS,
    canvas: canvasEnNS,
    gomoji: gomojiEnNS,
    project: projectEnNS,
  },
  vi: {
    common: commonViNS,
    resume: resumeViNS,
    audioVisualizer: audioVisualizerViNS,
    animeAPI: animeAPIViNS,
    canvas: canvasViNS,
    gomoji: gomojiViNS,
    project: projectViNS,
  },
} as const;

export const initializeI18N = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      returnNull: false,
      debug: getEnv() === Environments.local,
      fallbackLng: "en",
      ns: [
        "common",
        "resume",
        "audioVisualizer",
        "animeAPI",
        "canvas",
        "gomoji",
        "project",
      ],
      defaultNS,
      resources,
      interpolation: { escapeValue: false },
    });
};

export const customI18N = i18n;
