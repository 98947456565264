import { library } from "@fortawesome/fontawesome-svg-core";
import * as fab from "@fortawesome/free-brands-svg-icons";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as fal from "@fortawesome/pro-light-svg-icons";
import * as far from "@fortawesome/pro-regular-svg-icons";
import * as fad from "@fortawesome/pro-duotone-svg-icons";

export const initializeIconList = () => {
  const iconList = [
    fab.faReact,
    fab.faGoogle,
    fab.faPython,
    fab.faUnity,
    fab.faMailchimp,
    fab.faAws,
    fab.faWindows,
    fab.faPython,

    fab.faGithub,
    fab.faLinkedin,

    far.faCommentSmile,
    far.faCartPlus,
    far.faVrCardboard,
    far.faCode,
    far.faAtomAlt,
    far.faPalette,

    // * Project card
    far.faTimesCircle,
    far.faArrowRight,
    fad.faCircle,
    far.faQrcode,

    // * General
    far.faChevronRight,
    far.faChevronLeft,
    far.faEllipsisH,
    far.faUserNinja,
    far.faToriiGate,
    far.faMoon,
    far.faSun,
    fas.faPlus,
    fas.faTimes,
    fas.faInfo,
    far.faLevelDownAlt,
    far.faGlobe,
    far.faCodeBranch,
    far.faExclamationCircle,
    far.faExclamationTriangle,
    far.faTimes,
    far.faInfo,
    far.faSearch,
    far.faMinus,
    far.faPlus,

    // * Skill metadata
    fas.faYinYang,
    fas.faBrain,
    fas.faCode,
    fas.faSquareRootAlt,
    far.faSquareRootAlt,
    fas.faUsers,
    fas.faMusic,

    // * Route info
    far.faHeart,
    far.faAddressCard,
    far.faLightbulbOn,

    // * Music player
    fal.faBackward,
    fal.faPlay,
    fal.faForward,
    fal.faVolume,
    fal.faVolumeXmark,
    fal.faPause,
    fal.faCompactDisc,
  ];
  // Set up icon libraries
  library.add(...iconList);
};
