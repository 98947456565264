const size = 64; // Size in pixel // ! Keep this small to avoid query clogged
export const rpgClasses = [
  {
    name: "React",
    imgSrc: `https://img.icons8.com/bubbles/${size}/000000/react.png`,
    sourceUrl: "https://icons8.com/icon/Vra58PN2KmI5/react",
  },
  {
    name: "Unity 3D",
    imgSrc: `https://img.icons8.com/ios-filled/${size}/000000/unity.png`,
    sourceUrl: "https://icons8.com/icon/39848/unity",
  },
  {
    name: "Github",
    imgSrc: `https://img.icons8.com/dusk/${size}/000000/github.png`,
    sourceUrl: "https://icons8.com/icon/46565/github",
  },
  {
    name: "Git",
    imgSrc: `https://img.icons8.com/color/${size}/000000/git.png`,
    sourceUrl: "https://icons8.com/icon/20906/git",
  },
  {
    name: "Firebase",
    imgSrc: `https://img.icons8.com/color/${size}/000000/firebase.png`,
    sourceUrl: "https://icons8.com/icon/62452/firebase",
  },
  {
    name: "GCP",
    imgSrc: `https://img.icons8.com/color/${size}/000000/google-cloud-platform.png`,
    sourceUrl: "https://icons8.com/icon/20774/google-cloud-platform",
  },
  {
    name: "Javascript",
    imgSrc: `https://img.icons8.com/color/${size}/000000/javascript.png`,
    sourceUrl: "https://icons8.com/icon/108784/javascript",
  },
  {
    name: "Thinker",
    imgSrc: `https://img.icons8.com/cotton/${size}/000000/innovation.png`,
    sourceUrl: "https://icons8.com/icon/65185/innovation",
  },
  {
    name: "Web Design",
    imgSrc: `https://img.icons8.com/cotton/${size}/000000/web-design.png`,
    sourceUrl: "https://icons8.com/icon/65363/web-design",
  },
  {
    name: "Sass",
    imgSrc: `https://img.icons8.com/color/${size}/000000/sass.png`,
    sourceUrl: "https://icons8.com/icon/QBqFNfPPB2Kx/sass",
  },
  {
    name: "Typescript",
    imgSrc: `https://img.icons8.com/color/${size}/000000/typescript.png`,
    sourceUrl: "https://icons8.com/icon/uJM6fQYqDaZK/typescript",
  },
  {
    name: "Python",
    imgSrc: `https://img.icons8.com/dusk/${size}/000000/python.png`,
    sourceUrl: "https://icons8.com/icon/121464/python",
  },
  {
    name: "AWS",
    imgSrc: `https://img.icons8.com/color/${size}/000000/amazon-web-services.png`,
    sourceUrl: "https://icons8.com/icon/33039/amazon-web-services",
  },
  {
    name: "Piano",
    imgSrc: `https://img.icons8.com/cotton/${size}/000000/piano.png`,
    sourceUrl: "https://icons8.com/icon/56029/piano",
  },
  {
    name: "Math",
    imgSrc: `https://img.icons8.com/dusk/${size}/000000/math.png`,
    sourceUrl: "https://icons8.com/icon/46667/math",
  },
  {
    name: "Taekwondo",
    imgSrc: `https://img.icons8.com/color/${size}/000000/yin-yang.png`,
    sourceUrl: "https://icons8.com/icon/15282/yin-yang",
  },
  {
    name: "Naruto",
    imgSrc: `https://img.icons8.com/clouds/${size}/000000/naruto-sign.png`,
    sourceUrl: "https://icons8.com/icon/3qDPLMWBepbb/naruto-sign",
  },
  {
    name: "C#",
    imgSrc: `https://img.icons8.com/color/${size}/000000/c-sharp-logo.png`,
    sourceUrl: "https://icons8.com/icon/55251/c-sharp-logo",
  },
  {
    name: "C++",
    imgSrc: `https://img.icons8.com/color/${size}/000000/c-plus-plus-logo.png`,
    sourceUrl: "https://icons8.com/icon/40669/c++",
  },
];
