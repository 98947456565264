import {
  animeAPIPic,
  audioVisualizerPic,
  bubblePic,
  flockingPic,
  terminalResumePic,
} from "assets/projects";
import LoadingScreen from "components/commons/LoadingScreen";
import React, { lazy } from "react";

const HomePage = lazy(() => import("pages/HomePage"));
const ResumePage = lazy(() => import("pages/ResumePage"));
const ProjectsPage = lazy(() => import("pages/ProjectsPage"));

const TerminalResume = lazy(() => import("pages/TerminalResume"));
const AudioVisualizerPage = lazy(
  () => import("pages/subPages/AudioVisualizerPage"),
);
const Crossword = lazy(() => import("pages/subPages/Crossword"));
const UnderConstruction = lazy(() => import("pages/UnderConstruction"));

const AnimeAPIPage = lazy(() => import("pages/subPages/AnimeAPIPage"));
const CanvasBubble = lazy(
  () => import("pages/subPages/canvasProjects/CanvasBubble"),
);
const CanvasFlocking = lazy(
  () => import("pages/subPages/canvasProjects/CanvasFlocking"),
);

// https://color.mediaandme.be/-q21P2CdAcHFm-TawVTGP02 <--- dark
// https://color.mediaandme.be/mXXRPF---sG2TxraYt4dP02 <--- light

export const redirectInfo = {
  // From: To
  "/": "/home",
} as const;

export const canvasProjects = [
  {
    displayName: "Flocking Simulation!",
    path: "/project/canvas/flocking",
    component: <CanvasFlocking />,

    // * Project stuff
    imgSrc: flockingPic,
    showProject: true,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["HTML Canvas", "Sandbox"],
    projectBody: ["canvas", "reactStackSmall"],
  },
  {
    displayName: "Bubble!",
    path: "/project/canvas/bubble",
    component: <CanvasBubble />,

    // * Project stuff
    imgSrc: bubblePic,
    showProject: true,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["HTML Canvas", "Sandbox"],
    projectBody: ["canvas", "reactStackSmall"],
  },
] as const;

export const generalProjects = [
  // * Project route
  {
    displayName: "Anime API",
    path: "/project/anime",
    component: <AnimeAPIPage />,

    // * Project stuff
    imgSrc: animeAPIPic,
    showProject: true,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["Anime", "Restful API"],
    projectBody: ["restful", "reactStack", "animeStack", "netflixDesc"],
  },
  {
    displayName: "Terminal Resume",
    path: "/project/resume/terminal",
    component: <TerminalResume />,

    // * Project stuff
    imgSrc: terminalResumePic,
    showProject: true,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["Simple Terminal"],
    projectBody: ["reactStack", "terminalDesc"],
  },
  {
    displayName: "Audio Visualizer",
    path: "/project/audio/visualizer",
    component: <AudioVisualizerPage />,

    // * Project stuff
    imgSrc: audioVisualizerPic,
    showProject: true,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["HTML Canvas", "Howler JS"],
    projectBody: ["reactStack", "audioVisualizerDesc", "canvas"],
  },
  {
    displayName: "Crossword",
    path: "/project/crossword",
    component: <Crossword />,
    // TODO: Change this info later
    // * Project stuff
    imgSrc: audioVisualizerPic,
    showProject: false,
    techstack: [{ icon: ["fab", "react"], displayName: "React" }],
    tagList: ["HTML Canvas", "Howler JS"],
    projectBody: ["reactStack", "audioVisualizerDesc", "canvas"],
  },
] as const;

/**
 * : {
  displayName: string;
  icon: [string, string] | readonly [string, string];
  path: string;
  component: JSX.Element;
}[]
 */
export const mainRoute = [
  // * Main
  {
    displayName: "Home",
    icon: ["far", "heart"],
    path: "/home",
    component: <HomePage />,
  },
  {
    displayName: "Resume",
    icon: ["far", "address-card"],
    path: "/resume",
    component: <ResumePage />,
  },
  {
    displayName: "Project",
    icon: ["far", "lightbulb-on"],
    path: "/project",
    component: <ProjectsPage />,
  },
] as const;

const exportData = [
  ...mainRoute,

  // * General projects
  ...generalProjects,

  // * Canvas projects
  ...canvasProjects,

  // * Misc
  {
    displayName: "Under construction!",
    path: "/uc",
    component: <UnderConstruction />,
  },
  {
    displayName: "Loading Screen",
    path: "/loading",
    component: <LoadingScreen />,
  },
];

export default exportData;
